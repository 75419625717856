<template>
    <li class="nav-item">
        <div class="btn-group w-100">
            <a class="nav-link w-100" :class="{ active: $route.path.startsWith(link.to) }" :to="link.to" @click="expanded = !expanded" href="#">
                <fai v-if="link.icon" :icon="link.icon" />
                {{ link.title }}
                <span class="float-end">
                    <fai :icon="expanded ? 'caret-down' : 'caret-left'" />
                </span>
            </a>
        </div>
        <b-collapse :value="expanded">
            <li v-for="sublink in link.children" :key="sublink.to" class="nav-item w-100">
                <router-link class="nav-link" href="#" :to="sublink.to" :class="{ active: $route.path.startsWith(sublink.to) }">
                    <span class="ps-3">
                        <fai v-if="sublink.icon" :icon="sublink.icon" />
                        {{ sublink.title }}
                    </span>
                </router-link>
            </li>
        </b-collapse>
    </li>
</template>

<script>
export default {
    props: {
        link: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        expanded: false,
    }),
    beforeMount() {
        this.expanded = this.isChildrenActive()
    },
    methods: {
        isChildrenActive() {
            for (const sublink of this.link.children)
                if (this.$route.path.startsWith(sublink.to))
                    return true
            return false
        }
    }
};
</script>

<style scoped>
.main-nav nav a, ul {
    background-color: #2c3e50;
    color: #ecf0f1;
}

.main-nav nav a.active {
    background-color: #16a085;
}
</style>