<template>
    <div class="position-fixed bottom-0 end-0 p-3" style="z-index: 11">
        <div class="toast-container">
            <div class="toast show" role="alert" aria-atomic="true" v-for="toast in toasts" :key="toast.id"  :class="{[`border-${toast.variant}`]: true}">
                <div class="toast-header">
                    <strong class="me-auto">{{ toast.title }}</strong>
                    <button type="button" class="btn-close" v-if="toast.closeable" @click="deleteToast(toast.id)" />
                </div>
                <div class="toast-body">
                    {{ toast.body }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import EventBus from '@/event-bus'
import {guidGenerator} from '@/utils'

export default {
    data: () => ({
        toasts: []
    }),
    methods: {
        deleteToast(id) {
            this.toasts = this.toasts.filter(toast => toast.id !== id)
        }
    },
    mounted() {
        EventBus.$on('add-toast', (payload) => {
            this.toasts.push({
                ...payload,
                id: guidGenerator(),
                closeable: true,
                createdAt: Date.now()
            })
        })
    }
}
</script>