import { authApi } from '../api'

export const countriesModule = {
    state: () => ({
        countries: []
    }),
    mutations: {
        setCountries(state, countries) {
            state.countries = countries
        }
    },
    actions: {
        async fetchCountries({commit, getters}) {
            if (getters.countries.length === 0)
                commit('setCountries', (await authApi.getCountries()).data)
        }
    },
    getters: {
        countries: state => state.countries,
        countryOptions: state => state.countries.map(c => c.code),
        countryByCode: state => code => state.countries.find(country => country.code === code)
    }
}