<template>
    <router-link :class="{'active': $route.path.startsWith(link.to)}" class="nav-link" :to="link.to">
        <fai v-if="link.icon" :icon="link.icon" />
        {{ link.title }}
    </router-link>
</template>

<script>
export default {
    props: {
        link: {
            type: Object,
            required: true
        }
    }
}
</script>