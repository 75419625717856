import { authApi } from "../api";

export const organizationsModule = {
    state: () => ({
        organizations: [],
    }),
    mutations: {
        setOrganizations(state, organizations) {
            state.organizations = organizations;
        },
    },
    actions: {
        async fetchOrganizations({ commit, getters }) {
            if (getters.hosts.length === 0)
                commit(
                    "setOrganizations",
                    (await authApi.getOrganizations()).data,
                );
        },
    },
    getters: {
        organizations: (state) => state.organizations,
        organizationById: (state) => (organization_id) =>
            state.organizations.find((org) => org.id === organization_id),
    },
};
