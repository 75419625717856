import Vue from 'vue'
import VueRouter from 'vue-router'
import ContentBase from '../components/ContentBase'
import store from '../store'

Vue.use(VueRouter)

const routes = [
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/Login.vue')
    },
    {
        path: '/',
        name: 'base',
        component: ContentBase,
        children: [
            {
                path: 'users',
                name: 'users',
                component: () => import(/* webpackChunkName: "users" */ '../views/users/Users.vue'),
                meta: {
                    title: 'Benutzerverwaltung'
                }
            },
            {
                path: 'users/:user_id',
                name: 'user',
                component: () => import(/* webpackChunkName: "user" */ "../views/users/User.vue"),
                meta: {
                    title: 'Benutzer'
                }
            },
            {
                path: 'users/:user_id/tokens',
                name: 'user_tokens',
                component: () => import(/* webpackChunkName: "user_tokens" */ "../views/users/UserTokens.vue"),
                meta: {
                    title: 'API Schlüssel von Benutzer'
                }
            },
            {
                path: 'users/:user_id/projects',
                name: 'user_projects',
                component: () => import(/* webpackChunkName: "user_projects" */ "../views/users/UserProjects.vue"),
                meta: {
                    title: 'Projekte von Benutzer'
                }
            },
            {
                path: 'users/:user_id/customer',
                name: 'customer',
                component: () => import(/* webpackChunkName: "customers" */ "../views/users/customer/Customer.vue"),
                meta: {
                    title: 'Kunde'
                }
            },
            {
                path: 'servers',
                name: 'servers',
                component: () => import(/* webpackChunkName: "servers" */ "../views/server/servers/Servers.vue"),
                meta: {
                    title: 'Servers'
                }
            },
            {
                path: 'servers/:server_id',
                name: 'server',
                component: () => import(/* webpackChunkName: "server" */ "../views/server/servers/Server.vue"),
                meta: {
                    title: 'Server'
                }
            },
            {
                path: 'hosts',
                name: 'hosts',
                component: () => import (/* webpackChunkName: "hosts" */ "../views/server/hosts/Hosts.vue"),
                meta: {
                    title: 'Hostsysteme'
                }
            },
            {
                path: 'hosts/:host_id',
                name: 'host',
                component: () => import(/* webpackChunkName: "host" */ "../views/server/hosts/Host.vue"),
                meta: {
                    title: 'Hostsystem'
                }
            },
            {
                path: 'storages',
                name: 'storages',
                component: () => import (/* webpackChunkName: "storages" */ "../views/server/storages/Storages.vue"),
                meta: {
                    title: 'Storages'
                }
            },
            {
                path: 'storages/:storage_id',
                name: 'storage',
                component: () => import(/* webpackChunkName: "storage" */ "../views/server/storages/Storage.vue"),
                meta: {
                    title: 'Storage'
                }
            },
            {
                path: 'storage-classes',
                name: 'storage_classes',
                component: () => import (/* webpackChunkName: "storage-classes" */ "../views/server/storage_classes/StorageClasses.vue"),
                meta: {
                    title: 'Storage Klassen'
                }
            },
            {
                path: 'storage-classes/:storage_class_id',
                name: 'storage_class',
                component: () => import(/* webpackChunkName: "storage-class" */ "../views/server/storage_classes/StorageClass.vue"),
                meta: {
                    title: 'Storage Klasse'
                }
            },
            {
                path: 'hosts/:host_id/servers',
                name: 'host_servers',
                component: () => import(/* webpackChunkName: "servers" */ "../views/server/servers/Servers.vue"),
                meta: {
                    title: 'Server von Host'
                }
            },
            {
                path: 'zones',
                name: 'zones',
                component: () => import(/* webpackChunkName: "zones" */ "../views/server/zones/Zones.vue"),
                meta: {
                    title: 'Zonen'
                }
            },
            //{
            //    path: 'buckets',
            //    name: 'S3 Buckets',
            //    component: () => import(/* webpackChunkName: "buckets" */ "../views/s3/buckets/Buckets.vue")
            //},
            {
                path: 'networks',
                name: 'networks',
                component: () => import(/* webpackChunkName: "networks" */ "../views/server/networks/Networks.vue"),
                meta: {
                    title: 'Netzwerke'
                }
            },
            {
                path: 'networks/:network_id',
                name: "network",
                component: () => import(/* webpackChunkName: "network" */ "../views/server/networks/Network.vue"),
                meta: {
                    title: 'Netzwerk'
                }
            },
            {
                path: 'files',
                name: 'files',
                component: () => import (/* webpackChunkName: "files" */ "../views/accounting/Files.vue"),
                meta: {
                    title: 'Dateien'
                }
            },
            {
                path: 'invoices',
                name: 'invoices',
                component: () => import (/* webpackChunkName: "invoices" */ "../views/accounting/Invoices.vue"),
                meta: {
                    title: 'Rechnungen'
                }
            },
            {
                path: 'logs',
                name: 'logs',
                component: () => import(/* webpackChunkName: "logs" */ "../views/Logs.vue"),
                meta: {
                    title: 'API Logs'
                }
            },
            {
                path: 'projects',
                name: 'projects',
                component: () => import(/* webpackChunkName: "projects" */ "../views/projects/Projects.vue"),
                meta: {
                    title: 'Projekte'
                }
            },
            {
                path: 'projects/:project_id',
                name: 'project',
                component: () => import(/* webpackChunkName: "project" */ "../views/projects/Project.vue"),
                meta: {
                    title: 'Projekt'
                }
            },
            {
                path: 'templates',
                name:'templates',
                component: () => import(/* webpackChunkName: "templates" */ "../views/server/templates/Templates.vue"),
                meta: {
                    title: 'Templates'
                }
            },
            {
                path: 'medias',
                name:'ISOs',
                component: () => import(/* webpackChunkName: "medias" */ "../views/server/media/Medias.vue"),
                meta: {
                    title: 'ISOs'
                }
            },
            {
                path: 'firewalls',
                name: 'firewalls',
                component: () => import(/* webpackChunkName: "firewalls" */ "../views/server/firewalls/Firewalls.vue"),
                meta: {
                    title: 'Firewalls'
                }
            },
            {
                path: 'firewalls/:firewall_id',
                name: 'firewall',
                component: () => import(/* webpackChunkName: "firewall" */ "../views/server/firewalls/Firewall.vue"),
                meta: {
                    title: 'Firewall'
                }
            },
            {
                path: 'contracts',
                name: 'contracts',
                component: () => import(/* webpackChunkName: "templates" */ "../views/users/customer/ServiceContracts.vue"),
                meta: {
                    title: 'Verträge'
                }
            },
            {
                path: 'pricing/ranges',
                name: 'price_ranges',
                component: () => import(/* webpackChunkName: "price_ranges" */ "../views/server/pricing/PriceRanges.vue"),
                meta: {
                    title: 'Preisbereiche'
                }
            },
            {
                path: 'pricing/ranges/:range_id',
                name: 'price_range',
                component: () => import(/* webpackChunkName: "price_range" */ "../views/server/pricing/PriceRange.vue"),
                meta: {
                    title: 'Preisbereich'
                }
            },
            {
                path: 'variants',
                name: "variants",
                component: () => import(/* webpackChunkName: "variants" */ "../views/server/variants/Variants.vue"),
                meta: {
                    title: 'Varianten'
                }
            },
            {
                path: 'volumes',
                name: 'volumes',
                component: () => import(/* webpackChunkName: "volumes" */ "../views/server/volumes/Volumes.vue"),
                meta: {
                    title: 'Volume'
                }
            },
            {
                path: '**',
                redirect: '/users'
            }
        ]
    },
    {
        path: '**',
        redirect: '/login'
    }
]

if (process.env.NODE_ENV === 'development') {
    routes.push()
}

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach(async (to, from, next) => {
    store.commit('setRouteDescription', '') 
    await store.dispatch('initAuth')
    if (!store.getters.isAuthenticated && to.name !== 'Login')
        return next('/login')
    return next()
})

export default router
