<template>
    <div class="main-nav h-100">
        <h1 class="text-center text-comfortaa">LUMASERV</h1>
        <nav class="nav flex-column nav-pill">
            <div v-for="link in links" :key="link.title">
                <single-navigation-item :link="link" v-if="!link.children"/>
                <multi-navigation-item :link="link" v-else />
            </div>
        </nav>
        <div class="bottom-0 position-absolute bottom-wrapper">
            <b-button class="w-100" variant="danger" @click="logout">
                <fai icon="arrow-right-from-bracket" />
                Abmelden
            </b-button>
        </div>
    </div>
</template>

<script>
import MultiNavigationItem from './MultiNavigationItem.vue'
import SingleNavigationItem from './SingleNavigationItem.vue'
export default {
    components: { SingleNavigationItem, MultiNavigationItem },
    data: () => ({
        links: [
            {title: "Benutzerverwaltung", to: "/users", icon: 'user'},
            {title: "Projekte", to: "/projects", icon: 'users'},
            {title: "Serververwaltung", icon: 'server', children: [
                {title: "Server", to: "/servers", icon: 'server'},
                {title: "Hostsysteme", to: "/hosts", icon: 'server'},
                {title: "Templates", to: "/templates", icon: 'file-signature'},
                {title: "Zonen", to: '/zones', icon: 'globe'},
                {title: "Storages", to: "/storages", icon: 'hard-drive'},
                {title: "Storage Klassen", to: "/storage-classes", icon: 'server'},
                {title: "Netzwerke", to: '/networks', icon: 'network-wired'},
                {title: "ISOs", to: '/medias', icon: 'hard-drive'},
                {title: "Firewalls", to: '/firewalls', icon: 'dragon'},
                {title: "Preise", to: '/pricing/ranges', icon: 'money-bill'},
                {title: "Varianten", to: '/variants', icon: 'clone' },
                {title: "Volumes", to: '/volumes', icon: 'hard-drive'}
            ]},
            /*{title: "Speicher", icon: 'hard-drive', children: [
                {title: "S3", to: '/buckets', icon: 'database'},
            ]},*/
            {title: 'API', icon: 'plug', children: [
                {title: "Logs", to: '/logs', icon: 'note-sticky'},
            ]},
            {title: "Buchhaltung", icon: 'cash-register', children: [
                {title: "Rechnungen", to: "/invoices", icon: 'receipt'},
                {title: "Verträge", to: "/contracts", icon: 'file-signature'}
            ]}
        ]
    }),
    methods: {
        logout() {
            this.$store.dispatch('logout').then(() => this.$router.push('/login'))
        }
    }
}
</script>

<style scoped>
.main-nav {
    background-color: #34495e;
    height: 100vh;
}

.main-nav h1 {
    color: #ecf0f1;
}

.main-nav nav a, ul {
    background-color: #2c3e50;
    color: #ecf0f1;
}

.main-nav nav a.active {
    background-color: #16a085;
}

.bottom-wrapper {
    min-width: 225px;
    width: 15%;
}

h1 {
    font-size: 2vw;
}
</style>