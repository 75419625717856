import Vue from "vue";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "./assets/css/bs-overrides.scss";
import "./assets/css/styles.scss";
import "@popperjs/core";
import "bootstrap";
import { coreApi, authApi, billingApi } from "./api";

import {
    BAccordionItem,
    BAlert,
    BBadge,
    BButton,
    BCheck,
    BCollapse,
    BContainer,
    BInput,
    BModal,
    BPagination,
    BRange,
    BSelect,
    BSpinner,
    BTab,
    BTable,
    BTextarea,
    BToast,
} from "@lumaserv/bootstrap5-vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faUser,
    faCopy,
    faFile,
    faServer,
    faArrowLeft,
    faArrowRight,
    faGlobe,
    faPlus,
    faKey,
    faTrash,
    faDatabase,
    faNetworkWired,
    faList,
    faReceipt,
    faArrowDown,
    faUsers,
    faCaretLeft,
    faCaretDown,
    faCashRegister,
    faPlug,
    faFileSignature,
    faDragon,
    faArrowUp,
    faMoneyBill,
    faFloppyDisk,
    faArrowRightFromBracket,
    faHardDrive,
    faNoteSticky,
    faMagnifyingGlass,
    faPencil,
    faClone,
} from "@fortawesome/free-solid-svg-icons";
import vSelect from "vue-select";

window.APP_CONFIG = window.APP_CONFIG || {};

Vue.config.productionTip = false;

library.add(
    faArrowRightFromBracket,
    faUser,
    faCopy,
    faFile,
    faServer,
    faArrowLeft,
    faArrowRight,
    faGlobe,
    faPlus,
    faPencil,
    faKey,
    faTrash,
    faDatabase,
    faNetworkWired,
    faList,
    faReceipt,
    faArrowDown,
    faUsers,
    faCaretLeft,
    faCaretDown,
    faCashRegister,
    faPlug,
    faHardDrive,
    faFileSignature,
    faDragon,
    faArrowUp,
    faMoneyBill,
    faFloppyDisk,
    faNoteSticky,
    faMagnifyingGlass,
    faClone,
);

Vue.component("b-container", BContainer);
Vue.component("b-table", BTable);
Vue.component("b-button", BButton);
Vue.component("b-btn", BButton);
Vue.component("b-badge", BBadge);
Vue.component("b-alert", BAlert);
Vue.component("b-select", BSelect);
Vue.component("b-input", BInput);
Vue.component("b-check", BCheck);
Vue.component("b-range", BRange);
Vue.component("b-toast", BToast);
Vue.component("b-modal", BModal);
Vue.component("b-spinner", BSpinner);
Vue.component("b-pagination", BPagination);
Vue.component("b-tab", BTab);
Vue.component("b-accordion-item", BAccordionItem);
Vue.component("b-collapse", BCollapse);
Vue.component("b-textarea", BTextarea);
Vue.component("fai", FontAwesomeIcon);
Vue.component("v-select", vSelect);

Vue.mixin({
    computed: {
        $coreApi: () => coreApi,
        $authApi: () => authApi,
        $billingApi: () => billingApi,
    },
});

Sentry.init({
    Vue,
    dsn:
        window.APP_CONFIG.sentry_dsn ||
        "https://653d2330b656d479174f41b286a1cde0@errors.lumaserv.eu/5",
    integrations: [
        new BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
    ],
    logErrors: true,
});

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");
