import { render, staticRenderFns } from "./ContentBase.vue?vue&type=template&id=6739559a&scoped=true"
import script from "./ContentBase.vue?vue&type=script&lang=js"
export * from "./ContentBase.vue?vue&type=script&lang=js"
import style0 from "./ContentBase.vue?vue&type=style&index=0&id=6739559a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6739559a",
  null
  
)

export default component.exports