import { coreApi } from "../api"

export const projectModule = {
    state: () => ({
        projectId: '',
        keys: []
    }),
    mutations: {
        setProjectId(state, projectId) {
            state.projectId = projectId
        },
        setKeys(state, keys) {
            state.keys = keys
        },
        removeKey(state, keyId) {
            state.keys = state.keys.filter(k => k.id !== keyId)
        },
        addKey(state, key) {
            state.keys.push(key)
        }
    },
    actions: {
        async fetchKeys({commit, state}) {
            commit('setKeys', (await coreApi.getSSHKeys({
                'filter[project_id]': state.projectId
            })).data)
        },
        async deleteKey({commit}, keyId) {
            const res = await coreApi.deleteSSHKey(keyId)
            if (res.success)
                commit('removeKey', keyId)
            return res
        },
        async createKey({commit, state}, body) {
            const res = await coreApi.createSSHKey({
                ...body,
                project_id: state.projectId
            })
            if (res.success)
                commit('addKey', res.data)
            return res
        }
    },
    getters: {
        keys: state => state.keys,
        keyOptions: state => state.keys.map(k => ({
            value: k.id,
            label: k.title
        }))
    }
}