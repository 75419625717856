export function mapUserType (user_type = '') {
    let props = {
        variant: 'primary',
        title: 'Kunde'
    }
    if (user_type === 'ADMIN') {
        props.variant = 'danger'
        props.title = 'Administrator'
    }

    return props
}

export function getObjectDiff(obj1, obj2) {
    return Object.keys(obj1)
        .filter(key => obj1[key] !== obj2[key])
        .reduce((prev, key) => ({...prev, [key]: obj1[key]}), {})
}

export function arrayToQueryString(prefix, arr) {
    const query = {}

    arr.forEach((val, i) => {
        query[`${prefix}[${i}]`] = val
    })

    return query
}

export function escapeHTML(unsafe) {
    return unsafe.replace(/[&<"']/g, function(m) {
        switch (m) {
        case '&':
            return '&amp;';
        case '<':
            return '&lt;';
        case '"':
            return '&quot;';
        default:
            return '&#039;';
        }
    })
}

export function guidGenerator() {
    let S4 = function() {
        return (((1+Math.random())*0x10000)|0).toString(16).substring(1);
    };
    return (S4()+S4()+"-"+S4()+"-"+S4()+"-"+S4()+"-"+S4()+S4()+S4());
}

export function formatState(state) {
    if (state === 'OPEN')
        return 'Offen'
    if (state === 'DRAFT')
        return 'Entwurf'
    if (state === 'FINAL')
        return 'Übermittelt'
    return 'Abgeschlossen'
}

export function getBadgeVariant(state) {
    switch (state) {
    case 'SUCCESS':
        return 'success'
    case 'FAILED':
        return 'danger'
    case 'PENDING':
        return 'warning'  
    default:
        return 'primary'
    }
}