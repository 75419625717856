import { coreApi } from "../api"

export const priceRangesModule = {
    state: () => ({
        priceRanges: []
    }),
    mutations: {
        setPriceRanges(state, priceRanges) {
            state.priceRanges = priceRanges
        },
        addPriceRange(state, priceRange) {
            state.priceRanges.push(priceRange)
        }
    },
    actions: {
        async fetchPriceRanges({commit, state}) {
            if (state.priceRanges.length > 0)
                return

            const res = await coreApi.getServerPriceRanges()
            if (res.success)
                commit('setPriceRanges', res.data)
        },
        async createPriceRange({commit}, priceRange) {
            const res = await coreApi.createServerPriceRange(priceRange)
            if (res.success)
                commit('addPriceRange', res.data)
            return res
        }
    },
    getters: {
        priceRanges: state => state.priceRanges,
        priceRangeOptions: state => [
            {
                value: null,
                text: '-- Kein Preisbereich --'
            },
            ...state.priceRanges.map(z => ({
                value: z.id,
                text: z.title
            }))
        ]
    }
}