import LUMASERVAuthClient from "./client/auth-client";
import LUMASERVBillingClient from "./client/billing-client";
import LUMASERVCoreClient from "./client/compute-client";
import EventBus from "./event-bus";
import router from "./router";
import store from "./store";

const createToasts = (variant, messages) =>
    messages.forEach((body) =>
        EventBus.$emit("add-toast", {
            body: body.message,
            variant,
        }),
    );

const successInterceptor = (res) => {
    createToasts("success", res.data.messages.infos);
    createToasts("danger", res.data.messages.errors);
    createToasts("warning", res.data.messages.warnings);
    return res;
};

const errorInterceptor = (err) => {
    createToasts("success", err.response.data.messages.infos);
    createToasts("danger", err.response.data.messages.errors);
    createToasts("warning", err.response.data.messages.warnings);
    if (err.response.status === 401) {
        store.dispatch("logout").then(() => router.push("/login"));
    }
    throw err;
};

export const coreApi = new LUMASERVCoreClient(
    window.APP_CONFIG && window.APP_CONFIG.core_url
        ? window.APP_CONFIG.core_url
        : "https://api.k8s.lumaserv.dev/compute",
);
export const authApi = new LUMASERVAuthClient(
    window.APP_CONFIG && window.APP_CONFIG.auth_url
        ? window.APP_CONFIG.auth_url
        : "https://api.k8s.lumaserv.dev/auth",
);
export const billingApi = new LUMASERVBillingClient(
    window.APP_CONFIG && window.APP_CONFIG.billing_url
        ? window.APP_CONFIG.billing_url
        : "https://api.k8s.lumaserv.dev",
);
coreApi.axios.interceptors.response.use(successInterceptor, errorInterceptor);
authApi.axios.interceptors.response.use(successInterceptor, errorInterceptor);
billingApi.axios.interceptors.response.use(
    successInterceptor,
    errorInterceptor,
);

export function setToken(token) {
    coreApi.setToken(token);
    authApi.setToken(token);
    billingApi.setToken(token);
}
