<template>
    <div id="app">
        <router-view />
    </div>
</template>

<script>
export default {
}
</script>

<style>
body {
    background-color: #ecf0f1;
}
body, html {
    overflow: hidden;
}
</style>