import Vue from "vue";
import Vuex from "vuex";

import { authApi, setToken } from "../api";
import { hostsModule } from "./hosts";
import { storagesModule } from "./storages";
import { countriesModule } from "./countries";
import { zonesModule } from "./zones";
import { variantsModule } from "./variants";
import { projectModule } from "./project";
import { storageClassesModule } from "./storage_classes";
import { templatesModule } from "./templates";
import { priceRangesModule } from "./priceRanges";
import { organizationsModule } from "./organizations";

Vue.use(Vuex);

const TOKEN_NAME = "token";

export default new Vuex.Store({
    state: {
        token: "",
        routeDescription: "",
    },
    mutations: {
        setToken(state, token) {
            state.token = token;
            window.localStorage.setItem(TOKEN_NAME, token);
            setToken(token);
        },
        setRouteDescription(state, description) {
            state.routeDescription = description;
        },
    },
    actions: {
        init({ getters, dispatch }) {
            if (getters.isAuthenticated) {
                dispatch("fetchHosts");
                dispatch("fetchStorages");
                dispatch("fetchStorageClasses");
                dispatch("fetchCountries");
                dispatch("fetchOrganizations");
            }
        },
        initAuth({ commit, getters }) {
            if (!getters.isAuthenticated) {
                commit(
                    "setToken",
                    window.localStorage.getItem(TOKEN_NAME) || "",
                );
            }
        },
        async login({ commit, dispatch }, loginInfo) {
            const res = await authApi.login(loginInfo);
            console.log(res);
            if (res.success) {
                commit("setToken", res.data.token);
                await dispatch("checkToken");
            }
            return res;
        },
        logout({ commit }) {
            commit("setToken", null);
        },
        checkToken() {
            return new Promise((resolve, reject) => {
                authApi
                    .validateSelf()
                    .then((res) => {
                        if (res.data.user.type === "ADMIN") {
                            resolve(res);
                        } else {
                            reject({
                                messages: {
                                    errors: [
                                        {
                                            message:
                                                "Der Account ist kein Administrator!",
                                        },
                                    ],
                                },
                            });
                        }
                    })
                    .catch((res) => reject(res));
            });
        },
    },
    modules: {
        hostsModule,
        storagesModule,
        storageClassesModule,
        countriesModule,
        zonesModule,
        variantsModule,
        projectModule,
        templatesModule,
        priceRangesModule,
        organizationsModule,
    },
    getters: {
        isAuthenticated: (state) =>
            state.token != null && state.token.length > 0,
        description: (state) => state.routeDescription,
        token: (state) => state.token,
    },
});
