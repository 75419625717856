import { coreApi } from '../api'

export const storagesModule = {
    state: () => ({
        storages: []
    }),
    mutations: {
        setStorages (state, storages) {
            state.storages = storages
        },
        addStorage (state, storage) {
            state.storages.push(storage)
        }
    },
    actions: {
        async fetchStorages({commit, getters}) {
            if (getters.storages.length === 0)
                commit('setStorages', (await coreApi.getServerStorages()).data)
        },
        async createStorage({commit}, newStorage) {
            const res = await coreApi.createServerStorage(newStorage)
            if (res.success)
                commit('addStorage', res.data)
        }
    },
    getters: {  
        storages: state => state.storages,
        storageById: state => storage_id => state.storages.find(s => s.id === storage_id),
        storageOptions: state => state.storages.map(z => ({
            value: z.id,
            text: z.external_id
        }))
    }
}