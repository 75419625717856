import { coreApi } from "../api"

export const templatesModule = {
    state: () => ({
        templates: []
    }),
    mutations: {
        setTemplates(state, templates) {
            state.templates = templates
        },
        addTemplate(state, template) {
            state.templates.push(template)
        }
    },
    actions: {
        async fetchTemplates({commit, getters}) {
            if (getters.templates.length === 0)
                commit('setTemplates', (await coreApi.getServerTemplates()).data)
        },
        async createTemplate({commit}, body) {
            const res = await coreApi.createServerTemplate(body)
            if (res.success)
                commit('addTemplate', res.data)
            return res
        }
    },
    getters: {
        templates: state => state.templates
    }
}