import { coreApi } from "../api"

export const variantsModule = {
    state: () => ({
        variants: []
    }),
    mutations: {
        setVariants(state, variants) {
            state.variants = variants
        },
        addVariant(state, variant) {
            state.variants.push(variant)
        },
        removeVariant(state, id) {
            state.variants = state.variants.filter(v => v.id !== id)
        }
    },
    actions: {
        async fetchVariants({commit, state}) {
            if (state.variants.length === 0)
                commit('setVariants', (await coreApi.getServerVariants()).data)
        },
        async deleteVariant({commit}, id) {
            const res = await coreApi.deleteServerVariant(id)
            if (res.success)
                commit('removeVariant', id)
        },
        async createVariant({commit}, variant) {
            const res = await coreApi.createServerVariant(variant)
            if (res.success)
                commit('addVariant', res.data)
            return res
        }
    },
    getters: {
        serverVariants: state => state.variants,
        serverVariantById: state => variant_id => state.variants.find(variant => variant.id === variant_id),
        serverVariantOptions: state => state.variants.map(variant => ({
            value: variant.id,
            text: variant.title
        }))
    }
}