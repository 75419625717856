import { coreApi } from '../api'

export const hostsModule = {
    state: () => ({
        hosts: []
    }),
    mutations: {
        setHosts (state, hosts) {
            state.hosts = hosts
        },
        addHost (state, host) {
            state.hosts.push(host)
        }
    },
    actions: {
        async fetchHosts({commit, getters}) {
            if (getters.hosts.length === 0)
                commit('setHosts', (await coreApi.getServerHosts()).data)
        },
        async createHost({commit}, newHost) {
            const res = await coreApi.createServerHost(newHost)
            if (res.success)
                commit('addHost', res.data)
        }
    },
    getters: {  
        hosts: state => state.hosts,
        hostById: state => host_id => state.hosts.find(host => host.id === host_id),
    }
}