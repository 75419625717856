<template>
    <div class="d-flex">
        <toast-manager />
        <div class="navigation">
            <navigation />
        </div>
        <div class="align-self-end mt-3 content">
            <b-container fluid class="pb-3">
                <h1>{{ $route.meta.title }}</h1>
                <h5 v-if="!!description" class="text-secondary">{{ description }}</h5>
                <hr>
                <router-view :key="$route.path"  />
            </b-container>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Navigation from './navigation/Navigation.vue'
import ToastManager from './ToastManager.vue'

export default {
    components: { Navigation, ToastManager },
    computed: mapGetters(['description']),
    async beforeMount() {
        await this.$store.dispatch('init')
    }
}
</script>

<style scoped>
.navigation {
    min-width: 225px;
    width: 15%;
}

.content {
    overflow: hidden;
    overflow-y: scroll;
    height: 100vh;
    width: 85vw;
}
</style>