import { coreApi } from "../api"

export const zonesModule = {
    state: () => ({
        zones: []
    }),
    mutations: {
        setZones(state, zones) {
            for(let zone of zones) {
                if(zone.config && zone.config.proxmox_mock_template)
                    zone.config.proxmox_mock_template = JSON.stringify(zone.config.proxmox_mock_template)
            }
            state.zones = zones
        },
        addZone(state, zone) {
            state.zones.push(zone)
        },
        updateZone(state, zone) {
            if(zone.config && zone.config.proxmox_mock_template)
                zone.config.proxmox_mock_template = JSON.stringify(zone.config.proxmox_mock_template)
            const i = state.zones.find(z => z.id === zone.id)
            state.zones[i] = zone
        }
    },
    actions: {
        async fetchZones({commit, state}) {
            if (state.zones.length === 0)
                commit('setZones', (await coreApi.getAvailabilityZones()).data)
        },
        async createZone({commit}, newZone) {
            if(newZone.config.proxmox_mock_template)
                newZone.config.proxmox_mock_template = JSON.parse(newZone.config.proxmox_mock_template)
            const res = await coreApi.createAvailabilityZone(newZone)
            if (res.success)
                commit('addZone', res.data)
        }
    },
    getters: {
        zones: state => state.zones,
        zoneById: state => zone_id => state.zones.find(zone => zone.id === zone_id),
        zoneOptions: state => state.zones.map(zone => ({
            value: zone.id,
            text: zone.title
        }))
    }
}