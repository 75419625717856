import { coreApi } from '../api'

export const storageClassesModule = {
    state: () => ({
        storageClasses: []
    }),
    mutations: {
        setStorageClasses (state, storageClasses) {
            state.storageClasses = storageClasses
        },
        addStorageClass (state, storageClass) {
            state.storageClasses.push(storageClass)
        }
    },
    actions: {
        async fetchStorageClasses({commit, getters}) {
            if (getters.storageClasses.length === 0)
                commit('setStorageClasses', (await coreApi.getServerStorageClasses()).data)
        },
        async createStorageClass({commit}, newStorageClass) {
            const res = await coreApi.createServerStorageClass({
                ...newStorageClass,
                storage_ids: [newStorageClass.storage_ids]
            })
            if (res.success)
                commit('addStorageClass', res.data)
        }
    },
    getters: {  
        storageClasses: state => state.storageClasses,
        storageClassById: state => storage_class_id => state.storageClasses.find(s => s.id === storage_class_id),
        storageClassOptions: state => state.storageClasses.map(s => ({
            value: s.id,
            text: s.title
        }))
    }
}